body {
	font-family: Thicccboi, sans-serif;
	color: var(--text);
	font-size: 1.125rem;
	line-height: 1.667em;
	font-weight: 500;
	background-color: var(--body-bg);
}

a {
	color: var(--link);
}

h1,
h2,
h3,
h4,
h5,
h6 {
	--heading-text: #fff;
	font-weight: 700;
	color: var(--text);
	margin-bottom: 1rem;
	color: var(--heading-text);
}

h1 {
	font-size: 3rem;
	line-height: 1.2em;
	letter-spacing: 0.02em;

	@include bp(tabletplus) {
		font-size: 3.75rem;
	}
}

h2 {
	font-size: 2.125rem;
	line-height: 1.182em;

	@include bp(tabletplus) {
		font-size: 2.75rem;
	}
}

section h2 {
	margin-bottom: 2rem;
}

h3 {
	font-size: 1.5rem;
	line-height: 1.5em;
}

h4 {
	font-size: 1.375rem;
	line-height: 1.273em;
}

h5 {
	font-size: 1.125rem;
	line-height: 1.333em;
}

h6 {
	font-size: 1rem;
	line-height: 1.375em;
}

p {
	margin-bottom: 1.25rem;
	color: var(--neutral-500);
	font-size: 1rem;
	line-height: 1.4;

	@include bp(tabletplus) {
		font-size: 1.125rem;
		line-height: 1.7;
	}
}

a {
	--link-text: #fff;
	--link-text-hover: #ff4895;

	-webkit-transition: color 300ms ease;
	transition: color 300ms ease;
	color: var(--link-text);
	text-decoration: underline;

	&:hover {
		color: var(--link-text-hover);
	}
}

strong {
	font-weight: 700;
	color: var(--neutral-300);
}

.prompt {
	--prompt-text: var(--neutral-500);

	margin-bottom: 1.5rem;
	color: var(--prompt-text);

	&--inline {
		font-size: 1rem;
		margin: 0 0.5rem 0.25rem 0.25rem;
		line-height: 1.4;
	}
}

.legalese {
	margin: 1rem 0;
	text-align: center;
	font-size: 0.875rem;
}

/* LINK --- */
.link {
	--link-return-text: var(--secondary-3);

	&--return {
		display: inline-flex;
		align-items: center;
		gap: 0.5rem;
		text-decoration: none;
		color: var(--link-return-text);

		&::before {
			@include fa-icon("\f104", 1.5rem);
		}
	}
}

/* STATUS --------- */
.status {
	display: inline-flex;
	align-items: center;
	padding-inline: 0.5rem;
	gap: 0.5rem;
	font-size: 0.875rem;

	&--verified {
		color: var(--success);
	}
	&--not-verified {
		color: var(--error);
	}
}

/* UTILS --------- */
.text-center {
	text-align: center;
}
.text-right {
	text-align: right;
}

.text-muted {
	color: var(--neutral-400);
}
.text-small {
	font-size: 0.875rem;
}

.text-warning {
	color: var(--warning);
}

.data-label {
	color: var(--neutral-500);
	text-transform: uppercase;
	font-size: 0.875rem;
}

.data-value {
	display: inline-flex;
	align-items: center;
}
