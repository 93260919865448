/* --------------------------------- */
/* HERO (Home Page)                  */
/* --------------------------------- */

.hero {
    --hero-bg: var(--neutral-800);
    --hero-text: var(--neutral-100);

    max-width: 70rem;
    margin: 0 auto;
    padding: 0 2rem;
    background-color: var(--hero-bg);
    color: var(--hero-text);
    text-align: center;

    h1 {
        font-size: 2.5rem;

        @include bp(tabletplus) {
            font-size: 3.5rem;
        }
    }

    em {
        font-size: 2.5rem;
        font-style: normal;
        text-transform: uppercase;
        letter-spacing: 2px;
        display: block;
        font-weight: 700;
        color: var(--accent);
        margin-top: 1.25rem;
        line-height: 2;
    }

    &__content {
        font-size: 1.75rem;
        line-height: 1.4;
    }
}
