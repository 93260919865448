:root {
	--secondary-1: #ff4895;
	--secondary-2: #6e4fe9;
	--secondary-3: #1766ff;
	--secondary-4: #2d42ff;
	--secondary-5: #1d1a27;

	--neutral-800: #13111a;
	--neutral-700: #302c3f;
	--neutral-600: #5e5b71;
	--neutral-500: #88849c;
	--neutral-400: #b7b4c7;
	--neutral-300: #d9d7e6;
	--neutral-200: #f5f4fa;
	--neutral-100: #ffffff;

	--gradient-start: #6f86ff;
	--gradient-mid: #b372ce;
	--gradient-stop: #ff7586;


	--body-bg: var(--neutral-800);
	--text: var(--neutral-100);

	--header-height: 5rem;
	--header-desktop-height: 6rem;
	--accent: var(--secondary-1);

	--max-content-width: 80rem;

	--link: var(--neutral-100);

	--success: #00ff00;
	--error: #ff0000;
	--warning: #ff7586;
}
