/* --------------------------------- */
/* RANK                              */
/* --------------------------------- */

.rank {
    width: 1.5rem;
    height: 1.5rem;
    border-radius: 50%;
    font-size: 0.75rem;
    display: grid;
    place-items: center;

    &--increase {
        background-color: var(--secondary-2);
        color: var(--neutral-100);
    }

    &-group {
        white-space: nowrap;
    }
}
