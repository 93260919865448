/* --------------------------------- */
/* MODAL                             */
/* --------------------------------- */

.modal {
	--modal-bg: rgba(0, 0, 0, 0.9);

	transform: scale(0);
	opacity: 0;
	position: fixed;
	inset: 0;
	background: var(--modal-bg);
	z-index: 9999;
	display: grid;
	place-content: center;
	padding: 1rem;
	transition: all 0.3s ease;

	&--active {
		transform: scale(1);
		opacity: 1;
	}

	&__content {
		padding: 1.5rem;
		border: 2px solid var(--neutral-700);
		border-radius: 1rem;
		background-color: var(--neutral-800);
		width: min(calc(100% - 2rem), 40rem);
		margin: 0 auto;

		p {
			font-size: 1rem;
			line-height: 1.4;

			@include bp(tabletplus) {
				font-size: 1.125rem;
				line-height: 1.7;
			}
		}
	}

	&__header {
		display: flex;
		justify-content: space-between;
		margin-bottom: 1.5rem;
	}

	.group__user {
		font-size: 1.125rem;
	}

	.group__user-status {
		font-size: 1rem;
		color: var(--neutral-500);
	}

	&__actions {
		display: flex;
		flex-direction: column;
		gap: 0.5rem;

		@include bp(mobileplus) {
			flex-direction: row;
			justify-content: center;
		}
	}
}
