/* --------------------------------- */
/* TABLE                             */
/* --------------------------------- */

table {
	border-collapse: collapse;
	width: 100%;

	&.data-table {
		max-width: 60rem;
		margin: 0 auto 3rem;

		&--full-width {
			max-width: 100%;
		}
	}

	th,
	td {
		text-align: left;
		padding: 0.5rem;
		font-size: 0.875rem;
	}

	th {
		a {
			text-decoration: none;
			color: var(--neutral-500);
		}
		&.sort {
			a {
				color: var(--neutral-300);
			}
		}
	}

	td {
		border: 1px solid var(--neutral-700);
		border-width: 1px 0;
		color: var(--neutral-500);
		line-height: 1.4;
		vertical-align: top;

		&.ranked {
			padding: 2rem 0;
			position: relative;

			&::before {
				content: "";
				position: absolute;
				top: 0;
				left: 50%;
				width: 1px;
				height: 100%;
				background-color: var(--neutral-700);
				z-index: -1;
			}
		}

		&.show-more {
			padding: 0;
			border: none;
		}

		.group-user {
			display: flex;
			gap: 0.375rem;
			align-items: center;
			color: var(--neutral-100);
		}
	}
}

.filter {
	display: flex;
	flex-direction: column;
	gap: 1rem;
	justify-content: center;
	align-items: center;
	color: var(--neutral-500);

	@include bp(tabletplus) {
		flex-direction: row;
		gap: 1rem;
	}

	&--data-table {
		max-width: 60rem;
		margin: 2rem auto 1rem;
	}

	&__items {
		display: flex;
		align-items: center;
		gap: 0.25rem;
	}
	&__item {
		display: inline-flex;
		align-items: center;

		&:not(:last-child)::after {
			@include fa-icon("\f054", 1.25rem);
			color: inherit;
			margin: 0 0.25rem;

			@include bp(mobileplus) {
				margin: 0 0.5rem;
			}
		}

		.btn {
			font-size: 0.875rem;
			padding: 0.25rem 0.75rem;

			@include bp(mobileplus) {
				font-size: 1rem;
				padding: 0.5rem 1.25rem;
			}
		}

		&--selected {
			.btn {
				background-color: var(--neutral-700);
			}
		}
	}
}

.car-info {
	display: grid;
	gap: 1rem;
	margin: 0 auto;

	@include bp(mobileplus) {
		width: 35rem;
		grid-template-columns: 1fr 1fr;
	}

	@include bp(tabletplus) {
		width: 40rem;
	}

	.cards--car-details & {
		@include bp(mobileplus) {
			grid-template-columns: 1fr;
		}
		@include bp(tabletplus) {
			grid-template-columns: 1fr;
		}
	}

	.car-data {
		background-color: var(--neutral-700);
		padding: 1rem;
		border-radius: 0.5rem;
		margin: 0 auto;
		width: 100%;
		display: flex;
		flex-direction: column;
		gap: 1rem;

		@include bp(mobileplus) {
			grid-column: 1 / span 2;
		}

		&__row {
			display: flex;
			justify-content: space-between;
			gap: 0.5rem;

			&--rank {
				align-items: center;
				justify-content: space-around;
				gap: 2rem;
				margin-bottom: 1rem;
			}
		}

		&__mileage {
			color: var(--neutral-500);
			font-size: 1rem;
		}

		.car__status {
			justify-content: flex-end;
		}

		&__rank {
			font-size: 1rem;
			font-weight: 700;
			color: var(--neutral-100);
		}

		&__image {
			img {
				max-width: 8rem;
			}
		}
	}

	.car--rankings {
		background-color: var(--neutral-700);
		grid-column: 1 / span 2;

		.card__description {
			margin-bottom: 0;
			p {
				margin-bottom: 0;
			}
		}
	}

	.btn--full-width {
		margin: 0 auto;
	}
}
