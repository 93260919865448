/* --------------------------------- */
/* LISTS                             */
/* --------------------------------- */

ul,
ol {
	margin-bottom: 2rem;
	padding-left: 2.5rem;

	li {
		margin-bottom: 0.5rem;
		color: var(--neutral-500);
	}
}

.list {
	--list-item-separator: #e7e7e7;

	&--links {
		border-top: 1px solid var(--list-item-separator);
		a {
			display: block;
			padding: 0.75rem 0.25rem;
			border-bottom: 1px solid var(--list-item-separator);
		}
	}

	&--bulleted {
		list-style-type: disc;
	}

	&--radio-buttons {
		padding-left: 0;

		li {
			text-align: left;
		}
	}

	&--nested {
		padding-left: 0;
		margin-bottom: 1rem;

		li {
			text-align: left;
			margin-bottom: 0;
			line-height: 1.2;
		}
	}

	&--selling {
		width: 8rem;
		margin: 1rem auto;
	}

	&--cars {
		padding: 0 1.25rem;
		display: flex;
		flex-direction: column;
		gap: 1.5rem;
	}

	&--garage-cars {
		display: grid;
		gap: 3rem;
		margin-bottom: 3rem;

		@include bp(tabletplus) {
			grid-template-columns: 1fr 1fr;
		}

		.car-info {
			width: 100%;
			@include bp(tabletplus) {
				width: 100%;
			}
		}
	}
}

.car {
	background-color: var(--neutral-700);
	border-radius: 0.5rem;
	padding: 0.75rem 1rem;

	display: grid;
	grid-template-columns: repeat(2, 1fr);
	gap: 0.5rem;

	&__type {
		color: var(--neutral-500);
		font-size: 1rem;
		line-height: 1.4;
	}

	&__meta {
		color: var(--neutral-500);
		font-size: 1rem;
		line-height: 1.4;
		text-align: right;
	}

	&__status {
		display: flex;
		gap: 0.5rem;
		margin: 0 0.25rem;

		&--gold {
			color: var(--gold);
		}

		&::before {
			@include fa-icon("\f111", 1rem);
			font-weight: bold;
			color: var(--gold);
		}
	}

	&__image {
		img {
			border-radius: 0.25rem;
		}
	}
	&__info {
		font-size: 1rem;
		line-height: 1.4;
	}
	&__actions {
		text-align: right;
		align-self: end;
	}
	&__vin {
		grid-column: 1 / -1;
	}
}

span.car__status {
	display: inline-flex;
}
