/* --------------------------------- */
/* FOOTER                            */
/* --------------------------------- */

.footer {
    --footer-bg: #000;
    --footer-text: var(--neutral-500);
    --footer-separator: var(--neutral-500);
    --footer-social-bg: var(--neutral-700);
    --footer-social-text: var(--neutral-500);
    --footer-social-hover-bg: var(--secondary-2);
    --footer-social-hover-text: var(--neutral-100);

    display: grid;
    align-items: center;
    place-content: center;
    gap: 0.5rem;

    padding: 2rem;
    margin-top: 2rem;
    background-color: var(--footer-bg);
    color: var(--footer-text);

    @include bp(mobileplus) {
        grid-template-columns: repeat(2, 1fr);
    }

    &__logo {
        text-align: center;
        img {
            max-width: 14rem;
        }

        @include bp(mobileplus) {
            text-align: left;
        }
    }

    &__contact {
        display: flex;
        flex-direction: column;
        align-items: center;

        @include bp(mobileplus) {
            flex-direction: row;
            gap: 0.5rem;
        }
    }

    &__phone {
        @include bp(mobileplus) {
            &::after {
                content: "|";
                color: var(--footer-separator);
                margin-left: 0.5rem;
            }
        }
    }

    &__social {
        align-self: end;
        display: flex;
        justify-content: center;
        gap: 0.75rem;
        padding-left: 0;

        @include bp(mobileplus) {
            justify-content: flex-end;
        }

        a {
            text-decoration: none;
            display: block;
            display: grid;
            place-items: center;
            border-radius: 50%;
            background-color: var(--footer-social-bg);
            width: 2rem;
            height: 2rem;
            border-radius: 0.5rem;
            background-image: linear-gradient(315deg, #434bff, #ff4b81);
            transition-property: transform;
            font-size: 1.125rem;
            font-weight: 400;
            text-decoration: none;

            &:hover {
                transform: scale(1.1);
                background-color: var(--footer-social-hover-bg);
                color: var(--footer-social-hover-text);
            }
        }
    }

    &__copyright {
        text-align: center;
        font-size: 0.875rem;

        @include bp(mobileplus) {
            text-align: left;
        }
    }

    &__links,
    &__legalese {
        display: flex;
        justify-content: center;

        @include bp(mobileplus) {
            justify-content: flex-end;
        }

        a {
            font-size: 0.875rem;
            text-decoration: none;
            color: var(--footer-text);

            &:not(:last-child) {
                &::after {
                    content: "|";
                    color: var(--footer-separator);
                    margin: 0 0.5rem;
                }
            }
        }
    }
}
