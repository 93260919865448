/* ---------------------------- */
/* TABS                         */
/* ---------------------------- */

.tabs {
    margin: 2rem 0 0;
    padding: 0;
    list-style: none;
    display: flex;
    justify-content: center;
    gap: 0.5rem;
    border-bottom: 0.25rem solid var(--neutral-600);

    &__item {
        margin-bottom: 0;

        .btn--tab {
            background-color: var(--neutral-700);
            color: var(--neutral-100);
            padding: 0.75rem;
            border-radius: 0.5rem 0.5rem 0 0;
            text-decoration: none;
            font-size: 0.875rem;
            position: relative;

            @include bp(mobileplus) {
                font-size: 1rem;
            }

            &.active {
                background-color: var(--neutral-600);
                color: var(--neutral-100);
            }

            &[data-count] {
                &::before {
                    content: attr(data-count);
                    position: absolute;
                    top: -0.5rem;
                    right: -0.25rem;
                    font-size: 0.75rem;
                    font-weight: 500;
                    color: var(--neutral-100);
                    background-color: var(--secondary-1);
                    border-radius: 0.25rem;
                    padding: 0.125rem 0.25rem;
                    line-height: 1;
                    text-align: center;
                }
            }
        }
    }
}

.tab-content {
    padding: 1.5rem 1rem;
    margin-bottom: 2rem;

    &__pane {
        display: none;

        &.active {
            display: block;
        }

        h3 {
            margin-bottom: 0.5rem;
        }
    }
}
