/* --------------------------------- */
/* FORM                              */
/* --------------------------------- */

.form {
	--input-bg: var(--neutral-700);
	--input-focus-bg: var(--secondary-5);
	--input-label: var(--neutral-300);

	display: flex;
	flex-direction: column;
	gap: 1.5rem;

	&--no-top-margin {
		margin-top: 0;
	}

	&--rides {
		@include bp(tabletplus) {
			flex-direction: row;
			gap: 1rem;
			justify-content: space-between;
		}

		.form__group {
			flex: 1;
		}
	}

	&--inline {
		gap: 0.75rem;
		margin-bottom: 1.5rem;

		@include bp(tabletplus) {
			display: flex;
			flex-direction: row;
			gap: 0.75rem;
			margin-bottom: 1.5rem;

			.form__group:first-child {
				flex: 1;
				margin-bottom: 0;
			}
			.btn {
				align-self: stretch;
			}
		}
	}

	.verification-code {
		display: flex;
		flex-direction: row;
		justify-content: center;
		gap: 0.5rem;
		margin-bottom: 1.5rem;
	}

	&__group {
		display: flex;
		flex-direction: column;
		gap: 0.125rem;
		margin-bottom: 0.5rem;

		&--no-label {
			.form__label {
				display: none;
			}
		}

		.btn {
			@include bp(mobileplus) {
				display: inline-block;
				width: auto;
				min-width: 20rem;
			}

			+ .btn {
				margin-top: 1rem;
				@include bp(mobileplus) {
					margin-top: 0;
					margin-left: 1.5rem;
				}
			}
		}

		&--cta {
			// align-self: flex-start;
			margin-top: 0.5rem;

			@include bp(mobileplus) {
				flex-direction: row;
			}

			.btn--login {
				align-self: stretch;
				width: 100%;
			}
		}
	}

	&__image {
		display: block;
		margin-bottom: 1rem;
		border-radius: 0.5rem;
	}

	&__label,
	label {
		display: block;
		color: var(--input-label);
		margin-left: 0.25rem;
		font-size: clamp(1.0625rem, 2vw, 1.25rem);
		line-height: 1.333em;
		font-weight: 500;
		margin-bottom: 0.25rem;
		margin-left: 0.5rem;
	}

	&__input {
		background-color: var(--input-bg);
		padding: 0.625rem 1rem;
		min-height: 3rem;
		border: none;
		border-radius: 0.75rem;
		font-size: 1rem;

		&:hover {
			background-color: var(--input-focus-bg);
		}

		&:focus {
			background-color: var(--input-focus-bg);
			outline: 0;
			box-shadow: 0 0 0.125rem 0.125rem var(--secondary-2);
		}

		&--code {
			width: 3rem;
			text-align: center;

			@include bp(mobileplus) {
				width: 4rem;
				height: 4rem;
				font-size: 1.75rem;
			}
		}
	}

	&__textarea {
		background-color: var(--input-bg);
		padding: 0.625rem 1rem;
		border: none;
		border-radius: 0.75rem;
		font-size: 1rem;
		height: 12rem;
	}

	&__select {
		padding: 0.5rem;
		font-size: 1rem;
		border: none;
		border-radius: 1000px;
	}

	&__textarea {
		height: 7rem;
		border: none;

		&--short {
			height: 5rem;
		}
	}

	&-control {
		margin-bottom: 1rem;

		&--radio {
			margin-bottom: 0.5rem;
		}
	}
}

/* ---------------------------- */
/* SELECT                       */
/* ---------------------------- */

select {
	--select-bg: #122a60;
	--select-text: #dfdfdf;
	--select-hover-bg: #294f92;
	--select-bg: #264f88;
	--select-disabled-bg: transparent;
	--select-disabled-color: var(--neutral-600);

	--select-disabled-border: var(--neutral-600);
	-webkit-appearance: none;
	display: block;
	font-family: inherit;
	font-size: 1rem;
	appearance: none;
	border: 1px solid transparent;
	background: var(--select-bg)
		url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGhlaWdodD0iNDhweCIgdmlld0JveD0iMCAwIDI0IDI0IiB3aWR0aD0iNDhweCIgZmlsbD0iI0ZGRkZGRiI+PHBhdGggZD0iTTAgMGgyNHYyNEgweiIgZmlsbD0ibm9uZSIvPjxwYXRoIGQ9Ik0xNi41OSA4LjU5TDEyIDEzLjE3IDcuNDEgOC41OSA2IDEwbDYgNiA2LTZ6Ii8+PC9zdmc+);
	background-repeat: no-repeat;
	background-position: right 0.75rem center;
	background-size: 0.75rem;
	// background-image: linear-gradient(90deg, #6f86ff, #b372ce 49%, #ff7586);
	color: var(--select-text);
	cursor: pointer;
	transition: all 0.1s ease-in-out;

	&[disabled] {
		background: var(--select-disabled-bg);
		color: var(--select-disabled-color);
		border: 2px solid var(--select-disabled-border);
	}
}

/* ---------------------------- */
/* SWITCH                       */
/* ---------------------------- */

.form-control__toggle-switch {
	--toggle-default: #7c7c7c;
	--toggle-selected: #78b3f0;
	--toggle-thumb: #e5e5e5;

	.light-theme & {
		--toggle-default: #b5b5b5;
		--toggle-selected: hsla(213, 98%, 60%, 1);
		--toggle-thumb: #58585b;
	}

	border-bottom: 1px solid var(--neutral-700);
	padding: 1rem 0.25rem;

	[type="checkbox"] {
		position: absolute;
		clip: rect(0, 0, 0, 0);

		&:checked {
			+ label {
				&::before {
					background-color: var(--toggle-selected);
					transform: translateX(1.5rem);
				}
				&::after {
					background-color: var(--toggle-selected);
					opacity: 0.3;
				}
			}
		}
	}

	label {
		display: flex;

		align-items: center;
		gap: 1rem;
		padding-left: 3.5rem;
		position: relative;
		cursor: pointer;

		&::before {
			content: "";
			position: absolute;
			z-index: 1;
			left: 0;
			top: 0.375rem;
			width: 1.25rem;
			height: 1.25rem;
			border-radius: 50%;
			background-color: var(--toggle-thumb);
			box-shadow: 0 0 0.25rem 1px rgba(0, 0, 0, 0.25);
			transition: transform 0.2s ease;
		}

		&::after {
			content: "";
			position: absolute;
			left: 0;
			top: 0.625rem;
			width: 2.5rem;
			height: 0.75rem;
			border-radius: 0.5rem;
			background-color: var(--toggle-default);
			z-index: 0;
		}
	}
}

/* ---------------------------- */
/* RADIO BUTTONS                */
/* ---------------------------- */

input[type="radio"] {
	display: none;

	& + label {
		--radio-button-unselected: var(--gradient-start);
		--radio-button-hover: var(--neutral-300);
		--radio-button-selected: var(--gradient-stop);
		--radio-disabled-bg: transparent;
		--radio-disabled-color: var(--neutral-700);
		--radio-disabled-border: var(--neutral-700);

		cursor: pointer;
		user-select: none; /* prevents user selection of text --- */
		// display: inline-flex;
		align-items: center;
		text-indent: -1rem;
		margin-left: 2.5rem;

		&::before {
			@include fa-icon("\f111", 1.5rem);
			vertical-align: middle;
			color: var(--radio-button-unselected);
			margin-right: 0.5rem;
			font-weight: 500;
		}

		&:hover::before,
		&:focus::before {
			color: var(--radio-button-hover);
		}
	}

	&:checked {
		& + label::before {
			@include fa-icon("\f192", 1.5rem);
			color: var(--radio-button-selected);
			font-weight: 500;
		}
	}

	&:disabled {
		& + label {
			color: var(--radio-disabled-color);
			cursor: not-allowed;
		}

		& + label::before {
			color: var(--radio-disabled-border);
			cursor: not-allowed;
		}
	}
}

input[type="checkbox"].custom-checkbox {
	position: absolute;
	top: 0;
	left: 0;
	height: 1px;
	width: 1px;
	z-index: 0;
	overflow: hidden;
	clip: rect(0, 0, 0, 0);
	margin: -1px;
	outline: none;

	& + label {
		--checkbox-unchecked: var(--gradient-start);
		--checkbox-checked: var(--gradient-stop);
		--checkbox-disabled: var(--neutral-700);
		--checkbox-hover: var(--gradient-mid);
		--checkbox-label-color: var(--neutral-300);

		cursor: pointer;
		border-radius: 0.25rem;
		color: var(--checkbox-label-color);
		user-select: none;
		display: flex;
		align-items: center;
		gap: 0.5rem;
		margin-left: 0.5rem;
		font-size: 1.25rem;
		margin-bottom: 1rem;

		&:before {
			@include fa-icon("\f04d", 1.5rem);
			vertical-align: middle;
			color: var(--checkbox-unchecked);
			margin-right: 0.5rem;
		}
		&:hover::before,
		&:focus::before {
			color: var(--checkbox-hover);
		}
	}

	&:checked {
		color: var(--checkbox-checked);
		& + label::before {
			@include fa-icon("\f14a", 1.5rem);
			color: var(--checkbox-checked);
		}
	}

	&:disabled {
		& + label {
			color: var(--checkbox-disabled);
			cursor: not-allowed;
		}

		& + label::before {
			color: var(--checkbox-disabled);
			opacity: 0.5;
			cursor: not-allowed;
		}
	}
}
