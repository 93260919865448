/* --------------------------------- */
/* PRIMARY NAVIGATION                */
/* --------------------------------- */

.nav {
    --nav-bg: var(--neutral-800);
    --nav-link-text: var(--neutral-100);
    --nav-link-text-hover: var(--secondary-1);
    --nav-separator: rgba(255, 255, 255, 0.1);

    position: absolute;
    top: var(--header-height);
    left: 0;
    z-index: 100;
    height: calc(100vh - var(--header-height));
    width: min(100%, 30rem);

    opacity: 0;
    transform: translateX(-100vw);
    transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;
    background: var(--nav-bg);
    box-shadow: 3px 0 6px rgba(0, 0, 0, 0.2);

    @include bp(tabletplus) {
        position: static;
        opacity: 1;
        transform: translateX(0);
        height: auto;
        width: auto;
        box-shadow: none;
    }

    &.open {
        transform: translateX(0);
        opacity: 1;
    }

    &__list {
        margin-top: 2rem;
        padding-left: 0;

        @include bp(tabletplus) {
            margin: 0;
            padding: 0;
            display: flex;
        }

        li {
            &:not(:last-child) {
                a {
                    border-bottom: 1px solid var(--nav-separator);

                    @include bp(mobileplus) {
                        border-bottom: none;
                    }
                }
            }

            @include bp(tabletplus) {
                margin-bottom: 0;
            }
        }
    }

    a {
        color: var(--nav-link-text);
        display: block;
        padding: 0.75rem 1.5rem;
        text-decoration: none;
        font-size: 1.25rem;

        &:hover {
            color: var(--nav-link-text-hover);
        }

        @include bp(tabletplus) {
            border: none;
            padding: 1rem 0.75rem;
            font-size: 1rem;
        }
    }
    &__login {
        padding: 2rem;
    }
}
