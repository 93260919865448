/* --------------------------------- */
/* CARDS                             */
/* --------------------------------- */

.card-list {
	display: flex;
	flex-direction: column;
	gap: 1.75rem;

	@include bp(tabletplus) {
		flex-direction: row;
		gap: 2rem;
	}

	&--profile {
		display: grid;
		gap: 1.5rem;

		@include bp(mobileplus) {
			grid-template-columns: repeat(2, 1fr);
		}

		@include bp(tabletplus) {
			grid-template-columns: repeat(3, 1fr);
		}

		.card {
			padding: 1rem 1.5rem 0.5rem;
			cursor: pointer;
			transition: transform 0.2s ease-in-out;

			&:hover {
				transform: scale(1.025);
				box-shadow: 0 0.75rem 1rem rgba(0, 0, 0, 0.8);
			}
		}
	}

	&--centered {
		display: flex;
		justify-content: center;
		gap: 1.5rem;

		.card {
			@include bp(tabletplus) {
				padding: 2rem;
				max-width: 60rem;
			}
		}
	}
}

.cards-stacked {
	max-width: 80rem;
	margin: 0 auto 3rem;
	display: flex;
	flex-direction: column;
	gap: 1.5rem;

	@include bp(tabletplus) {
		.card {
			padding: 0;
			display: grid;
			grid-template-columns: 1fr 2fr;

			&__img {
				border-radius: 1.375rem 0 0 1.375rem;
				margin-bottom: 0;
			}

			&__content {
				padding: 1.5rem 2.5rem;
				align-self: center;
			}

			&:nth-child(2n) {
				grid-template-columns: 2fr 1fr;
				.card__img {
					order: 2;
					border-radius: 0 1.375rem 1.375rem 0;
				}
			}
		}
	}

	.card__content {
		padding: 0.5rem 1.5rem 2rem;

		@include bp(mobileplus) {
			padding: 1rem 2.5rem 2.5rem;
		}

		p {
			&:last-child {
				margin-bottom: 0;
			}
		}
	}

	&--chat {
		position: relative;

		&::before {
			content: "";
			position: absolute;
			top: 0;
			left: 50%;
			width: 2px;
			height: 100%;
			background-color: var(--neutral-700);
			opacity: 0.5;
			z-index: -1;
		}

		@include bp(tabletplus) {
			.card {
				grid-template-columns: 1fr;

				&:nth-child(2n) {
					grid-template-columns: 1fr;
				}

				&__content {
					padding-inline: 1.5rem;

					&:empty {
						padding: 0.5rem;
					}
				}
			}
		}
	}

	.card__cta {
		margin: 0;
	}
}

/* --------------------------------- */
/* CARDS - CAR DETAILS               */
/* --------------------------------- */

.cards--car-details {
	display: flex;
	flex-direction: column;
	gap: 1.5rem;
	position: relative;

	&::before {
		content: "";
		position: absolute;
		top: 0;
		left: 50%;
		width: 2px;
		height: 100%;
		background-color: var(--neutral-700);
		opacity: 0.5;
		z-index: -1;
	}
}

/* --------------------------------- */
/* CARD                              */
/* --------------------------------- */

.card {
	--card-bg: var(--secondary-5);
	--card-form-bg: var(--neutral-800);

	background-color: var(--card-bg);
	box-shadow: 0 5px 14px 0 rgba(0, 0, 0, 0.1);
	border-radius: 1.375rem;
	overflow: hidden;
	border: 1px solid var(--neutral-700);
	flex: 1;

	&--form {
		background-color: var(--card-form-bg);
		padding: 2rem 1.5rem 1.5rem;

		.form__input {
			background-color: var(--neutral-700);
		}
	}

	&--chat {
		margin-bottom: 1.5rem;
	}

	&--search {
		padding: 2rem 0 1.5rem;
		border: none;
		max-width: 64rem;
		margin: 0 auto;

		.form__label {
			text-align: center;
		}
	}

	&--padded {
		padding: 1.5rem 1.25rem;

		@include bp(mobileplus) {
			padding: 1rem 0.75rem 0.75rem;
		}

		@include bp(tabletplus) {
			padding: 2rem 1.5rem 1.5rem;
		}

		.card__description {
			margin-right: 0;
		}
	}

	&--border {
		background-color: var(--card-form-bg);
	}

	&--no-border {
		border: none;
	}

	&--login,
	&--narrow {
		max-width: 40rem;
		margin: 0 auto;

		.btn--full-width {
			max-width: none;
		}
	}

	&--login {
		.btn--full-width {
			max-width: none;
		}
	}

	&--centered {
		> * {
			text-align: center;
		}
	}

	@include bp(tabletplus) {
		display: flex;
		flex-direction: column;
		padding: 2.5rem 2rem;
	}

	&__header {
		display: flex;
		align-items: center;
		gap: 1rem;
		margin-bottom: 1.25rem;

		.card__title {
			margin-bottom: 0;
		}

		&--chat {
			padding: 1rem 1.5rem 0;
			justify-content: space-between;
			margin-bottom: 0;
		}
	}

	&__user {
		display: inline-flex;
		align-items: center;
		gap: 0.25rem;
	}

	&__elapsed-time {
		font-size: 0.75rem;
		color: var(--neutral-500);
	}

	&__icon {
		width: 4rem;
		aspect-ratio: 1;
		border-radius: 0.5rem;
		display: grid;
		place-items: center;
		background-color: var(--neutral-700);

		i {
			font-size: 2rem;
			color: var(--neutral-200);
		}

		img {
			width: 2.25rem;
			height: auto;
		}
	}

	&__img {
		height: 12rem;
		overflow: hidden;
		margin-bottom: 1.5rem;
		border-radius: 0.75rem 0.75rem 0 0;

		@include bp(tabletplus) {
			height: auto;
			margin-bottom: 0;
		}

		&--where-you-stand {
			background: url(../images/home_see_where_you_stand.jpg) no-repeat center;
			background-size: cover;
		}

		&--checkout-the-rankings {
			background: url(../images/home_checkout_the_rankings.jpg) no-repeat center;
			background-size: cover;
		}

		&--looking-to-buy {
			background: url(../images/home_looking_to_buy.jpg) no-repeat center;
			background-size: cover;
		}

		&--go-to-your-garage {
			background: url(../images/go_to_your_garage.jpg) no-repeat center;
			background-size: cover;
		}

		&--manage-your-account {
			background: url(../images/manage_your_account.jpg) no-repeat center;
			background-size: cover;
		}

		&--which-rides-for-sale {
			background: url(../images/which_rides_for_sale.jpg) no-repeat center;
			background-size: cover;
		}

		&--search-and-filter {
			background: url(../images/search_and_filter.jpg) no-repeat center;
			background-size: cover;
		}

		&--get-in-touch {
			background: url(../images/get_in_touch.jpg) no-repeat center;
			background-size: cover;
		}
	}

	&__img-inline {
		img {
			object-fit: cover;
		}
	}

	&__title {
		font-size: clamp(1.25rem, 3vw, 1.625rem);
		margin: 0 1.5rem 1rem 0;
	}

	&__description {
		margin: 0 1.5rem 1.5rem 0;
		flex: 1;
		line-height: 1.5;
	}

	a {
		text-decoration: none;
	}

	&__cta,
	&__actions {
		margin: 0 1.5rem 1rem 0;

		.btn {
			width: 100%;

			@include bp(tabletplus) {
				width: auto;
			}
		}
	}

	&__social {
		border-top: 2px solid var(--neutral-700);
		padding: 0.5rem;
		display: flex;
		justify-content: space-between;

		p {
			margin-bottom: 0;
		}

		.btn {
			padding: 0.25rem 0.75rem;
		}
	}

	&__footer {
		padding: 1.5rem 1rem 0;
	}

	/* Car Details --- */
	&--car {
		padding: 0;
		.card__content {
			padding: 1rem 1.25rem;
		}

		.card__description {
			font-size: 1rem;

			@include bp(tabletplus) {
				font-size: 1.125rem;
			}
		}
	}
}

.article {
	+ .article {
		margin-top: 2.5rem;
	}
}

/* FLEX GROUP --------- */
.card__row-group {
	display: flex;
	flex-direction: column;

	@include bp(tabletplus) {
		flex-direction: row;
		justify-content: space-between;
		align-items: center;

		> div {
			margin-bottom: 0;
		}
	}
}
