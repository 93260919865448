/* --------------------------------- */
/* BUTTONS                           */
/* --------------------------------- */

.btn {
	--btn-text: #dfdfdf;
	--btn-login-bg: #b1966b;
	--btn-login-text: #404040;
	--btn-primary-bg: #b1966b;
	--btn-primary-text: #404040;
	--btn-secondary-bg: #c7c7c7;

	background-color: transparent;
	color: var(--btn-text);
	border: none;
	padding: 0.5rem 1.5rem;
	border-radius: 0.25rem;

	&--nav {
		padding: 0.5rem;

		&::before {
			@include fa-icon("\f0c9", 1.75rem);
		}

		&.active {
			&::before {
				@include fa-icon("\f00d", 1.75rem);
			}
		}
	}
}

.btn {
	background-color: transparent;
	border: none;
	border-radius: 1000px;
	color: var(--neutral-100);
	font-size: 1.125rem;
	line-height: 1.111em;
	text-align: center;
	letter-spacing: 0.01em;
	text-decoration: none;

	&--primary {
		padding: 1.875rem 4.5rem;
		color: var(--neutral-100);
		font-weight: 700;
		background: linear-gradient(90deg, #6f86ff, #b372ce 49%, #ff7586);
	}

	&--primary-gradient {
		--color-bg: #13131a;
		display: inline-block;
		box-shadow: 0 3px 7px 0 rgba(19, 17, 26, 0.14);
		transition: transform 300ms ease;
		color: var(--neutral-100);
		background: linear-gradient(var(--color-bg), var(--color-bg)) padding-box,
			linear-gradient(90deg, #6f86ff, #b372ce 49%, #ff7586) border-box;
		border-radius: 50em;
		border: 0.125rem solid transparent;

		&:hover {
			transform: scale(1.02);
			color: var(--neutral-100);
		}
	}
	i {
		margin-right: 0.5rem;
	}

	&--disabled {
		pointer-events: none;
		color: var(--neutral-700);
		border-color: var(--neutral-700);
	}

	&--action {
		font-size: 0.75rem;
		padding: 0.25rem 0.5rem;
		color: var(--neutral-500);
		background-color: var(--neutral-700);
	}

	&--small {
		padding: 1rem 1.75rem;
	}

	&--large {
		padding: 1.25rem 4.25rem;
		line-height: 1.5rem;
		font-size: 1.375rem;
	}

	&--auto-width {
		display: inline-block;
		width: auto;
	}

	&--secondary {
		background-color: var(--neutral-700);
		transform: scale3d(1, 1, 1.01);
		transition: transform 300ms ease, background-color 300ms ease;
		transform-style: preserve-3d;

		&:hover {
			background-color: var(--secondary-5);
			transform: scale3d(1.04, 1.04, 1.01);
		}
	}

	&--login {
		background-color: var(--neutral-700);
	}

	&--nav {
		--nav-bg: var(--secondary-5);
		width: 3.25rem;
		height: 3.25rem;
		border-radius: 100%;
		display: grid;
		place-items: center;
		background-color: var(--neutral-700);

		&::before {
			@include fa-icon("\f0c9", 1.75rem);
		}

		&.active {
			&::before {
				@include fa-icon("\f00d", 1.75rem);
			}
		}
	}

	&--full-width {
		width: 100%;

		@include bp(mobileplus) {
			max-width: 20rem;
		}
	}

	&--rounded {
		width: 3.25rem;
		height: 3.25rem;
		padding: 0;
		border-radius: 100%;
		display: grid;
		place-items: center;

		i {
			font-size: 1.75rem;
			margin: 0;
		}
	}

	&--combo {
		padding: 1rem 1.5rem;
		display: flex;
		gap: 1.25rem;
		align-items: center;

		&-social {
			gap: 0.25rem;
		}

		&__icon {
			i {
				font-size: 1.75rem;
				margin: 0;
			}
			img {
				width: 2.25rem;
			}
		}

		&__label {
			text-align: left;
			color: var(--neutral-300);
		}
	}

	&--return {
		display: flex;
		justify-content: center;
		align-items: center;
		gap: 0.5rem;

		&::before {
			@include fa-icon("\f0d9", 1.25rem);
			font-weight: bold;
		}
	}

	&--show-more-top,
	&--show-more-bottom {
		max-width: none;
		color: var(--neutral-500);
		padding: 0.75rem 0;
		display: flex;
		align-items: center;
		justify-content: center;
		gap: 1rem;
	}

	&--show-more-top {
		border-radius: 1rem 1rem 0 0;

		&::before,
		&::after {
			@include fa-icon("\f062", 1rem);
		}
	}

	&--show-more-bottom {
		border-radius: 0 0 1rem 1rem;
		&::before,
		&::after {
			@include fa-icon("\f063", 1rem);
		}
	}
}
