/* Reset --- */
*,
*::before,
*::after {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
	font-family: inherit;
}

ul {
	list-style: none;
}

a {
	text-decoration: none;
}

img {
	display: inline-block;
	max-width: 100%;
	height: auto;
}
