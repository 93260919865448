/* ---------------------------- */
/* RESPONSIVE BREAKPOINTS       */
/* ---------------------------- */
// @include bp(mobileplus | tabletplus | laptopplus);

@mixin bp($point) {
    @if $point == mobileplus {
        @media (min-width: 40rem) {
            // 480px
            @content;
        }
    } @else if $point == tabletplus {
        @media (min-width: 60rem) {
            // 800px
            @content;
        }
    } @else if $point == laptopplus {
        @media (min-width: 70rem) {
            // 1120px
            @content;
        }
    } @else if $point == desktopplus {
        @media (min-width: 90rem) {
            // 1440px
            @content;
        }
    }
}

/* ---------------------------- */
/* FONT AWESOME ICONS           */
/* ---------------------------- */

@mixin fa-icon($icon, $size: 1.5rem) {
    content: $icon;
    font-family: "Font Awesome 5 Pro";
    font-size: $size; /* Preferred icon size */
    display: inline-block;
    text-transform: none;
    letter-spacing: normal;
    word-wrap: normal;
    white-space: nowrap;
    direction: ltr;
    font-weight: 300;

    /* Support for all WebKit browsers. */
    -webkit-font-smoothing: antialiased;

    /* Support for Safari and Chrome. */
    text-rendering: optimizeLegibility;

    /* Support for Firefox. */
    -moz-osx-font-smoothing: grayscale;

    /* Support for IE. */
    font-feature-settings: "liga";
}
