/* --------------------------------- */
/* FAQs                              */
/* --------------------------------- */

.faqs {
    --faq-border: var(--neutral-700);
    --question-color: var(--neutral-300);

    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin-bottom: 2rem;

    details {
        padding: 0.75rem 1.5rem;
        border: 0.125rem solid var(--faq-border);
        border-radius: 1rem;
        box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.1);

        &[open] {
            background-color: var(--neutral-700);
            summary {
                &::before {
                    transform: rotate(90deg);
                }
            }
        }
    }

    summary {
        font-weight: 500;
        color: var(--question-color);
        cursor: pointer;
    }

    p {
        margin: 0.5rem 1rem 0.5rem 1.125rem;
    }
}

details[open] summary ~ * {
    transform-origin: top;
    animation: sweep 0.2s linear;
}
summary::marker {
    color: transparent;
}

details summary {
    display: flex;
    align-items: center;

    &::before {
        content: "\f054";
        font-family: "Font Awesome 5 Pro";
        text-transform: initial;
        font-size: 0.875rem;
        transition: transform 0.2s linear;
        margin-right: 0.75rem;
    }
}
@keyframes sweep {
    0% {
        transform: scaleY(0);
    }
    100% {
        transform: scaleY(1);
    }
}
