/* --------------------------------- */
/* BADGES                             */
/* --------------------------------- */

.badges {
    display: grid;
    gap: 2rem;
    grid-template-columns: repeat(auto-fill, minmax(15rem, 1fr));
}

.badge {
    --badge-placeholder: #f63;

    display: flex;
    flex-direction: column;
    align-items: center;

    &__image {
        margin-bottom: 1rem;

        &--placeholder {
            background-color: var(--badge-placeholder);
            border-radius: 0.5rem;
            height: 10rem;
            width: 10rem;
            border-radius: 0.25rem;
        }
    }

    &__name {
        font-size: 1.25rem;
        font-weight: 500;
        margin-bottom: 0.5rem;
    }

    &__description {
        font-size: 1rem;
        font-weight: 400;
        line-height: 1.4;
        color: var(--neutral-600);
    }

    &--thumbnail {
        width: 1.25rem;
        height: 1.25rem;
        margin-right: 0.5rem;
        background-color: var(--badge-placeholder);
        border-radius: 0.125rem;
    }
}
