@font-face {
	font-family: "Icons Dark Template";
	src: url("../fonts/icons-dark-template.woff2") format("woff2");
	font-weight: 400;
	font-style: normal;
	font-display: swap;
}
@font-face {
	font-family: "Thicccboi";
	src: url("../fonts/THICCCBOI-Bold.ttf") format("truetype");
	font-weight: 700;
	font-style: normal;
	font-display: swap;
}
@font-face {
	font-family: "Thicccboi";
	src: url("../fonts/THICCCBOI-Medium.ttf") format("truetype");
	font-weight: 500;
	font-style: normal;
	font-display: swap;
}
@font-face {
	font-family: "Thicccboi";
	src: url("../fonts/THICCCBOI-SemiBold.ttf") format("truetype");
	font-weight: 600;
	font-style: normal;
	font-display: swap;
}
