/* --------------------------------- */
/* BREADCRUMB                        */
/* --------------------------------- */

.breadcrumb {
    --breadcrumb-separator: var(--neutral-500);
    --breadcrumb-text: var(--neutral-400);
    --breadcrumb-text-hover: var(--secondary-1);

    margin: 0 0 1rem;

    &__list {
        display: flex;
        align-items: center;
        padding-left: 0;
        white-space: nowrap;
        text-overflow: ellipsis;
    }

    li {
        font-size: 0.875rem;
        &::after {
            @include fa-icon("\f054", 0.875rem);
            font-weight: 600;
            color: var(--breadcrumb-separator);
            margin: 0 0.5rem 0;
        }
        a {
            text-decoration: none;
            color: var(--breadcrumb-text);

            &:hover {
                color: var(--breadcrumb-text-hover);
            }
        }
    }
}
