/* --------------------------------- */
/* GRADIENT UTILITY CLASSES          */
/* --------------------------------- */

.bg-gradient-blur-wrapper {
    position: absolute;
    width: 100%;
    max-width: 44%;
    min-height: 74%;
    opacity: 0.5;
    -webkit-filter: blur(100px);
    filter: blur(100px);
}

.bg-gradient-blur-wrapper.home-hero-1 {
    left: -209px;
    top: -230px;
}

.bg-gradient-blur-wrapper.home-hero-2 {
    right: -212px;
    bottom: 25px;
}

.bg-gradient-blur-wrapper.big {
    max-width: 35%;
    min-height: 90%;
}

.bg-gradient-blur-wrapper.big.about-hero {
    top: 147px;
    right: 0px;
    bottom: -148px;
}

.bg-gradient-blur-wrapper.big.values {
    bottom: 48px;
}

.bg-gradient-blur-wrapper.big.blog-hero {
    top: -160px;
    right: -226px;
}

.bg-gradient-blur-wrapper.big.blog-post {
    top: -329px;
    right: -177px;
}

.bg-gradient-blur-wrapper.home-steps {
    max-width: 40%;
    min-height: 40%;
}

.bg-gradient-blur-wrapper.blog {
    left: -224px;
    top: 467px;
    min-height: 37%;
}

.bg-gradient-blur-wrapper.blog-post-articles {
    left: -359px;
    bottom: -63px;
}

.bg-gradient-blur-wrapper.features-1 {
    left: -246px;
    top: 0px;
    min-height: 22%;
}

.bg-gradient-blur-wrapper.features-2 {
    right: 0px;
    bottom: 417px;
    min-height: 22%;
}

.bg-gradient-blur-wrapper.team-page-1 {
    left: -386px;
    top: -228px;
    min-height: 70%;
}

.bg-gradient-blur-wrapper.team-page-2 {
    right: -101px;
    bottom: -470px;
    min-height: 90%;
}

.bg-gradient-blur-wrapper.team {
    left: 0%;
    top: 0%;
    right: 0%;
    bottom: 0%;
    max-width: 100%;
    min-height: 100%;
}

.bg-gradient-blur-wrapper.careers-hero-1 {
    left: -396px;
    top: -469px;
    min-height: 83%;
}

.bg-gradient-blur-wrapper.careers-hero-2 {
    right: -286px;
    bottom: -325.208px;
    min-height: 83%;
}

.bg-gradient-blur-wrapper.careers-1 {
    left: -427px;
    top: 5px;
    min-height: 54%;
}

.bg-gradient-blur-wrapper.careers-2 {
    right: -322px;
    bottom: -402px;
    min-height: 54%;
}

.bg-gradient-blur-wrapper.integrations-hero {
    top: -116px;
    right: -98px;
    min-height: 80%;
}

.bg-gradient-blur-wrapper.integrations {
    left: -465.0069px;
    bottom: -281.444px;
    min-height: 80%;
}

.bg-gradient-blur-wrapper.integration {
    left: -583px;
    top: 133px;
    min-height: 50%;
}

.bg-gradient-blur-wrapper.more-integrations {
    right: -33px;
    bottom: -193.858px;
    min-height: 50%;
}

.bg-gradient-blur-wrapper.pricing {
    left: -455px;
    top: -126px;
    min-height: 78%;
}

.bg-gradient-blur-wrapper.plan-features {
    top: -92px;
    right: -136px;
    min-height: 60%;
}

.bg-gradient-blur-wrapper.plan-1 {
    left: 0px;
    top: -156px;
    min-height: 44%;
}

.bg-gradient-blur-wrapper.plan-2 {
    right: 0px;
    bottom: 0px;
    min-height: 44%;
}

.bg-gradient-blur-wrapper.contact-hero {
    right: 0px;
    bottom: 0px;
    min-height: 170%;
}

.bg-gradient-blur-wrapper.faqs {
    left: -598px;
    top: 69px;
    max-width: 105%;
    min-height: 59%;
}

.bg-gradient-blur-wrapper.not-found-1 {
    left: -403px;
    top: -145px;
    max-width: 57%;
    min-height: 85%;
}

.bg-gradient-blur-wrapper.not-found-2 {
    right: -249px;
    bottom: -300px;
}

.bg-gradient-blur-circle-1 {
    position: absolute;
    width: 70%;
    max-height: 70%;
    min-height: 70%;
    min-width: 70%;
    border-radius: 1000000px;
    background-color: #d220ff;
}

.bg-gradient-blur-circle-1.purple {
    background-color: #6923ff;
}

.bg-gradient-blur-circle-1.purple-2 {
    background-color: #6e4fe9;
}

.bg-gradient-blur-circle-1.purple-2.big {
    bottom: 0px;
    width: 87%;
    max-height: 87%;
    min-height: 87%;
    min-width: 87%;
}

.bg-gradient-blur-circle-1.pink {
    background-color: #ff4895;
}

.bg-gradient-blur-circle-1.blue {
    background-color: #1766ff;
}

.bg-gradient-blur-circle-2 {
    position: absolute;
    right: 0px;
    bottom: 0px;
    width: 70%;
    max-height: 70%;
    min-height: 70%;
    min-width: 70%;
    border-radius: 1000000px;
    background-color: #fe316f;
}

.bg-gradient-blur-circle-2.blue {
    background-color: #1766ff;
}

.bg-gradient-blur-circle-2.blue.left {
    right: auto;
}

.bg-gradient-blur-circle-2.blue-2 {
    background-color: #2d42ff;
}

.bg-gradient-blur-circle-2.blue-2.blog {
    left: 0px;
    right: auto;
    z-index: 1;
}

.bg-gradient-blur-circle-2.purple {
    background-color: #6923ff;
}

.bg-gradient-blur-circle-3 {
    position: absolute;
    top: 0px;
    right: 0px;
    width: 70%;
    max-height: 70%;
    min-height: 70%;
    min-width: 70%;
    border-radius: 1000000px;
    background-color: #6923ff;
}

.bg-gradient-blur-circle-3.pink {
    background-color: #ff4895;
}

.bg-gradient-blur-circle-3.pink.big {
    width: 87%;
    max-height: 87%;
    min-height: 87%;
    min-width: 87%;
}

.bg-gradient-blur-circle-3.pink.team {
    top: -12px;
    right: -77px;
}

.bg-gradient-blur-circle-3.pink.top {
    top: -41px;
    right: -112px;
    z-index: 1;
}
