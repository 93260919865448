/* --------------------------------- */
/* HEADER                            */
/* --------------------------------- */

.header {
    color: var(--header-text);
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    height: var(--header-height);
    padding: 1rem;

    @include bp(tabletplus) {
        height: var(--header-desktop-height);
        .btn--nav {
            display: none;
        }
    }

    &__logo {
        img {
            display: block;
            width: 7rem;

            @include bp(mobileplus) {
                width: 9rem;
            }
        }
    }
}
