/* --------------------------------- */
/* BODY                              */
/* --------------------------------- */

body {
	min-height: 100vh;
}

/* --------------------------------- */
/* OVERALL LAYOUT                    */
/* --------------------------------- */

main,
.main {
	width: min(85%, 80rem);
	margin: 0 auto;

	&--home {
		max-width: unset;
		background-color: var(--neutral-700);
		padding: 3rem 1rem;
		width: 100%;

		@include bp(tabletplus) {
			padding: 4rem 2rem;
		}
	}
}

section {
	> h2 {
		text-align: center;

		@include bp(mobileplus) {
			text-align: left;
		}
	}

	h3 {
		font-size: 2rem;
		margin-bottom: 2rem;
		font-weight: 600;
	}

	+ section {
		margin-top: 3rem;
	}
}

/* UTILS --------- */

hr {
	&.spacer {
		border: 0;

		&--vertical {
			margin: 2.5rem;
		}

		&--small {
			margin: 0.5rem;
		}
	}

	&.border {
		border: 0;
		border-top: 2px solid var(--neutral-700);
		margin: 1.5rem 0;
	}
}

/* CTA */
.cta {
	&--page {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		gap: 1rem;

		@include bp(mobileplus) {
			flex-direction: row;
		}

		.btn {
			width: 100%;

			@include bp(mobileplus) {
				width: auto;
				min-width: 20rem;
			}
		}
	}
}

/* Columnar Content --- */
.columns {
	display: flex;
	flex-direction: column;
	gap: 1rem;
	margin-bottom: 2rem;

	@include bp(mobileplus) {
		flex-direction: row;
		justify-content: space-between;
	}
}
